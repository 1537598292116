<template>
	<v-btn icon @click="click">
		<i v-if="!isFullscreen" class="fak fa-line-zoom-framing fs-regular fw-500" :title="$t('toolbar.maximize')" />
		<i v-else class="fas fa-compress" :title="$t('toolbar.minimize')" />
	</v-btn>
</template>

<script>
import screenfull from 'screenfull';

export default {
	name: 'Screenfull',
	props: {
		origin: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			isFullscreen: false
		};
	},
	mounted() {
		this.init();
		//console.warn('screenfull 1', screenfull.isFullscreen);
	},
	beforeDestroy() {
		this.destroy();
	},
	methods: {
		click() {
			if (!screenfull.enabled) {
				/*this.$message({
					message: 'you browser can not work',
					type: 'warning'
				});*/
				return false;
			}
			screenfull.toggle();
		},
		change() {
			//console.warn('screenfull 2', screenfull.isFullscreen);
			this.isFullscreen = screenfull.isFullscreen;
		},
		init() {
			if (screenfull.enabled) {
				screenfull.on('change', this.change);
			}
		},
		destroy() {
			if (screenfull.enabled) {
				screenfull.off('change', this.change);
			}
		}
	}
};
</script>

<style scoped>
.screenfull-svg {
	display: inline-block;
	cursor: pointer;
	fill: #5a5e66;
	width: 20px;
	height: 20px;
	vertical-align: 10px;
}
</style>
